<template>
  <v-switch
    v-bind="$attrs"
    v-model="pValue"
    inset
    @change="onInputChanged($event)"
    color="primary"
  >
    <v-tooltip v-bind="tooltipAttrs" bottom slot="label">
      <template v-slot:activator="{ on, attrs }">
        <span class="ml-4 mr-4">{{ $attrs.label }}</span>
        <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
          mdi-help
        </v-icon>
      </template>
      <span>{{ helpText }}</span>
    </v-tooltip>
  </v-switch>
</template>


<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    helpText: {
      type: String,
      default: "",
    },

    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
  },
  data() {
    return {
      pValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.pValue = newVal;
    },
  },
  methods: {
    onInputChanged(event) {
      this.$emit("input", event);
    }
  },
};
</script>


<style lang="scss">
</style>